import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import Header from './components/Header';
import Column from './components/Column';
import ethereumImg from './assets/ethereum.png';
import baseImg from './assets/base.png';
import blastImg from './assets/blast.png';
import avaxImg from './assets/avax.png';
import solanaImg from './assets/solana.png';
import pepeImg from './assets/pepe.png';
import brettImg from './assets/brett.png';
import andyImg from './assets/andy.png';
import landwolfImg from './assets/landwolf.png';
import birddogImg from './assets/birddog.png';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://marketcap-fetch.onrender.com';


function getMarketCapStyle(coinId) {
    if (coinId === 'andyerc') {
        return {
            fontWeight: 'bold',
            fontSize: '1.8em',
            color: getMarketCapColor(coinId),
            textShadow: '1px 0 0 #F8E77F, -1px 0 0 #F8E77F, 0 1px 0 #F8E77F, 0 -1px 0 #F8E77F'
        };
    } else {
        return {
            fontWeight: 'bold',
            fontSize: '1.8em',
            color: getMarketCapColor(coinId),
            textShadow: '1px 0 0 black, -1px 0 0 black, 0 1px 0 black, 0 -1px 0 black'
        };
    }
}

function getMarketCapColor(coinId) {
    switch (coinId) {
        case 'pepe': return '#9D2B13';
        case 'based-brett': return '#EA9EBA';
        case 'andyerc': return '#00000C';
        case 'landwolf-on-avax': return '#FFF600';
        case 'bird-dog-on-sol': return '#49E2CC';
        default: return 'lightgreen';
    }
}

function formatMarketCap(value, coinId) {
    let formattedValue;
    if (value < 1e6) {
        formattedValue = `$${(value / 1e3).toFixed(2)}K`;
    } else if (value < 1e9) {
        formattedValue = `$${(value / 1e6).toFixed((value / 1e6) >= 100 ? 1 : 2)}M`;
    } else {
        formattedValue = `$${(value / 1e9).toFixed((value / 1e9) >= 100 ? 1 : 2)}B`;
    }
    formattedValue = formattedValue.replace(/\.0$/, '');
    return <span style={getMarketCapStyle(coinId)}>{formattedValue}</span>;
}

function App() {
    const [coins, setCoins] = useState([
        { id: "pepe", bgColor: "#61A24E", buttonColor: "#9D2B13", chainImg: ethereumImg, profileImg: pepeImg, coinName: "Pepe", quote: "feels good man.", marketCap: "Fetching...", websiteUrl: "https://www.pepe.vip", chartUrl: "https://www.dextools.io/app/en/ether/pair-explorer/0xa43fe16908251ee70ef74718545e4fe6c5ccec9f?t=1713127513419", buyUrl: "https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x6982508145454Ce325dDbE47a25d4ec3d2311933", twitterUrl: "https://twitter.com/pepecoineth", telegramUrl: "https://t.me/enterpepe", chainLink: "https://etherscan.io/token/0x6982508145454ce325ddbe47a25d4ec3d2311933" },
        { id: 'based-brett', bgColor: "#6EE1FE", buttonColor: "#EA9EBA", chainImg: baseImg, profileImg: brettImg, coinName: "Brett", quote: "Chillin' like a champ – as usual.", marketCap: "Fetching...", websiteUrl: "https://www.basedbrett.com/", chartUrl: "https://www.dextools.io/app/en/base/pair-explorer/0x404e927b203375779a6abd52a2049ce0adf6609b?t=1708765825026", buyUrl: "https://www.okx.com/web3/dex-swap#inputChain=8453&inputCurrency=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&outputChain=8453&outputCurrency=0x532f27101965dd16442e59d40670faf5ebb142e4", twitterUrl: "https://twitter.com/BasedBrett", telegramUrl: "https://t.me/basedbrett", chainLink: "https://basescan.org/token/0x532f27101965dd16442e59d40670faf5ebb142e4" },
        { id: "andyerc", bgColor: "#F8E77F", buttonColor: "#00000C", chainImg: blastImg, profileImg: andyImg, coinName: "Andy", quote: "You got it, duuuude", marketCap: "Fetching...", websiteUrl: "https://andyonblast.io/", chartUrl: "https://www.dextools.io/app/en/blast/pair-explorer/0x713ea4a158dc5bcb451beeb13c000698a12f9720?t=1713127544491", buyUrl: "https://app.thruster.finance/?token2=0xd43d8adac6a4c7d9aeece7c3151fca8f23752cf8", twitterUrl: "https://twitter.com/AndyBlastL2", telegramUrl: "https://t.me/AndyBlastL2", chainLink: "https://blastscan.io/token/0xd43d8adac6a4c7d9aeece7c3151fca8f23752cf8" },
        { id: "landwolf-on-avax", bgColor: "#F7574D", buttonColor: "#FFF600", chainImg: avaxImg, profileImg: landwolfImg, coinName: "Landwolf", quote: "Got milk?", marketCap: "Fetching...", websiteUrl: "https://www.landwolfavax.com", chartUrl: "https://www.dextools.io/app/en/avalanche/pair-explorer/0x08db8eafeea89476bfc51b07613f430748ff350a?t=1713127760348", buyUrl: "https://traderjoexyz.com/avalanche/trade?outputCurrency=0x4f94b8aef08c92fefe416af073f1df1e284438ec", twitterUrl: "https://twitter.com/landwolfonavax", telegramUrl: "https://t.me/landwolfonavax", chainLink: "https://snowtrace.io/token/0x4f94b8aef08c92fefe416af073f1df1e284438ec?chainId=43114" },
        { id: "bird-dog-on-sol", bgColor: "#CCABF2", buttonColor: "#49E2CC", chainImg: solanaImg, profileImg: birddogImg, coinName: "Bird Dog", quote: "Classic shit, man.", marketCap: "Fetching...", websiteUrl: "https://www.solbirddog.com/", chartUrl: "https://www.dextools.io/app/en/solana/pair-explorer/DThR9WwS7qVUFswtabmbX7SRPZejpJgdy6CTB631kpgF?t=1713128161676", buyUrl: "https://jup.ag/swap/SOL-BIRDDOG_3XTp12PmKMHxB6YkejaGPUjMGBLKRGgzHWgJuVTsBCoP", twitterUrl: "https://twitter.com/SOLbirddog", telegramUrl: "https://t.me/birddogentry", chainLink: "https://solana.fm/address/3XTp12PmKMHxB6YkejaGPUjMGBLKRGgzHWgJuVTsBCoP" }
    ]);

        useEffect(() => {
        const fetchLatestMarketCaps = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/marketcap/latest`);
                if (response.data && Array.isArray(response.data)) {
                    const marketCapValues = response.data; // Assume the array is [Pepe, Brett, Andy, Landwolf, Bird Dog]
                    const updatedCoins = coins.map((coin, index) => {
                        const newMarketCap = formatMarketCap(marketCapValues[index], coin.id);
                        if (newMarketCap !== coin.marketCap) {
                            return { ...coin, marketCap: newMarketCap };
                        }
                        return coin;
                    });
                    setCoins(updatedCoins);
                }
            } catch (error) {
                console.error('Error fetching latest market caps:', error);
                setCoins(coins.map(coin => ({ ...coin, marketCap: "Error fetching data" })));
            }
        };

        fetchLatestMarketCaps(); // Fetch immediately when component mounts
        const intervalId = setInterval(fetchLatestMarketCaps, 30000); // Then set to fetch every 30 seconds

        // Cleanup function to clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures the effect only runs once on mount and not on every update
    

    return (
        <div className="App">
            <Header />
            <div className="columnsContainer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {coins.map((coin, index) => (
                    <Column
                        key={index}
                        bgColor={coin.bgColor}
                        buttonColor={coin.buttonColor}
                        buttonTextColor={coin.coinName === "Landwolf" ? "#000000" : "#ffffff"}
                        quote={coin.quote}
                        chainImg={coin.chainImg}
                        chainLink={coin.chainLink}
                        profileImg={coin.profileImg}
                        coinName={coin.coinName}
                        marketCap={coin.marketCap}
                        websiteUrl={coin.websiteUrl}
                        chartUrl={coin.chartUrl}
                        buyUrl={coin.buyUrl}
                        twitterUrl={coin.twitterUrl}
                        telegramUrl={coin.telegramUrl}
                    />
                ))}
            </div>
        </div>
    );
}

export default App;
