// Column.js
import React from 'react';
import { useRef, useState } from 'react';
import { onMouseEnter, onMouseLeave, toggleFlip } from './animations';
import { useButtonListeners, useShakeEffect } from './useEffects';
import './Column.css';

function Column({
    bgColor,
    chainImg,
    profileImg,
    coinName,
    marketCap,
    quote,
    websiteUrl,
    chartUrl,
    buyUrl,
    buttonColor,
    buttonTextColor,
    twitterUrl,
    telegramUrl,
    chainLink
}) {
    const columnRef = useRef(null);
    const profileImgRef = useRef(null);
    const flipButtonRef = useRef(null);
    const websiteBtnRef = useRef(null);
    const chartBtnRef = useRef(null);
    const buyBtnRef = useRef(null);
    const twitterBtnRef = useRef(null);
    const telegramBtnRef = useRef(null);
    const chainImageRef = useRef(null);
    const [isFlipped, setIsFlipped] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [isCracked, setIsCracked] = useState(false);

    useButtonListeners({ flipButtonRef, websiteBtnRef, chartBtnRef, buyBtnRef, twitterBtnRef, telegramBtnRef, chainImageRef, onMouseEnter, onMouseLeave, toggleFlip, isFlipped, setIsFlipped, columnRef });
    useShakeEffect(columnRef, setIsVisible, setIsCracked);

    if (!isVisible) {
        return null;
    }

    return (
        <div ref={columnRef} className={`column ${isCracked ? 'crack-effect' : ''}`} style={{ backgroundColor: bgColor }}>
            <button ref={flipButtonRef} className="flipButton"></button>
            <div className="imageContainer">
                <a href={chainLink} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img ref={chainImageRef} src={chainImg} alt="Chain logo" className="chainImage" />
                </a>
                <img src={profileImg} alt={`${coinName} logo`} className="profileImage" />
            </div>
            <h3 style={{ fontFamily: 'Inter', fontSize: '1.8em', color: '#0e1111' }}>{coinName}</h3>
            <p>{marketCap}</p>
            <p style={{ fontStyle: 'italic', fontSize: '1.2em', color: 'black', fontFamily:'Inter' }}>“{quote}”</p>
            <div className="buttons">
                <button ref={websiteBtnRef} className="button" style={{ backgroundColor: buttonColor, color: buttonTextColor }} onClick={() => window.open(websiteUrl, "_blank")}>
                    Website
                </button>
                <button ref={chartBtnRef} className="button" style={{ backgroundColor: buttonColor, color: buttonTextColor }} onClick={() => window.open(chartUrl, "_blank")}>
                    Chart
                </button>
                <button ref={buyBtnRef} className="button" style={{ backgroundColor: buttonColor, color: buttonTextColor }} onClick={() => window.open(buyUrl, "_blank")}>
                    Buy
                </button>
            </div>
            <div className="socialIcons">
                <button ref={twitterBtnRef} onClick={() => window.open(twitterUrl, "_blank")} className="socialButton">
                    <img src="/x.png" alt="Twitter" style={{ width: '24px', height: '24px' }} />
                </button>
                <button ref={telegramBtnRef} onClick={() => window.open(telegramUrl, "_blank")} className="socialButton">
                    <img src="/telegram.png" alt="Telegram" style={{ width: '24px', height: '24px' }} />
                </button>
            </div>
        </div>
    );
}

export default Column;
