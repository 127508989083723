// animations.js
import { gsap } from 'gsap';

export const onMouseEnter = (element, scale = 1.15) => {
    gsap.to(element, {
        scale: scale,
        duration: 0.3,
        ease: 'power1.out'
    });
};

export const onMouseLeave = (element, scale = 1) => {
    gsap.to(element, {
        scale: scale,
        duration: 0.3,
        ease: 'power1.out'
    });
};

export const toggleFlip = (isFlipped, setIsFlipped, columnRef) => {
    setIsFlipped(!isFlipped);
    gsap.to(columnRef.current, {
        rotationY: isFlipped ? 0 : 180,
        ease: "power1.inOut",
        duration: 0.8
    });
};
