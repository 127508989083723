// useEffects.js
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

export const useButtonListeners = ({ columnRef, flipButtonRef, websiteBtnRef, chartBtnRef, buyBtnRef, twitterBtnRef, telegramBtnRef, chainImageRef, onMouseEnter, onMouseLeave, toggleFlip, isFlipped, setIsFlipped }) => {
    useEffect(() => {
        const buttons = [
            websiteBtnRef, chartBtnRef, buyBtnRef, twitterBtnRef, telegramBtnRef, chainImageRef, flipButtonRef
        ].map(ref => ref.current);

        buttons.forEach(button => {
            button.addEventListener('mouseenter', () => onMouseEnter(button));
            button.addEventListener('mouseleave', () => onMouseLeave(button));
        });

        flipButtonRef.current.addEventListener('click', () => toggleFlip(isFlipped, setIsFlipped, columnRef));

        return () => {
            buttons.forEach(button => {
                button.removeEventListener('mouseenter', () => onMouseEnter(button));
                button.removeEventListener('mouseleave', () => onMouseLeave(button));
            });
            flipButtonRef.current.removeEventListener('click', () => toggleFlip(isFlipped, setIsFlipped, columnRef));
        };
    }, [isFlipped, setIsFlipped, columnRef, onMouseEnter, onMouseLeave, toggleFlip]);
};

export const useShakeEffect = (columnRef, setIsVisible, setIsCracked) => {
    const holdTimer = useRef(null);
    const saturationTimer = useRef(null);
    const crackTimer = useRef(null);
    const deleteTimer = useRef(null);
    const shakeTimeline = useRef(gsap.timeline({ paused: true, repeat: -1, yoyo: true }));

    useEffect(() => {
        shakeTimeline.current.to(columnRef.current, {
            x: '+=6',
            duration: 0.05,
            modifiers: {
                x: gsap.utils.unitize(x => parseFloat(x) % 10)
            }
        });

        const handleMouseDown = () => {
            holdTimer.current = setTimeout(() => {
                shakeTimeline.current.play();

                saturationTimer.current = setTimeout(() => {
                    columnRef.current.style.filter = "contrast(150%) saturate(150%)";

                    crackTimer.current = setTimeout(() => {
                        setIsCracked(true);

                        deleteTimer.current = setTimeout(() => {
                            setIsVisible(false);
                        }, 1000);
                    }, 1250);
                }, 1250);
            }, 750);

            document.addEventListener('mouseup', handleMouseUpOrLeave);
            document.addEventListener('mouseleave', handleMouseUpOrLeave);
        };

        const handleMouseUpOrLeave = () => {
             // Clear all set timeouts
             clearTimeout(holdTimer.current);
            clearTimeout(saturationTimer.current);
            clearTimeout(crackTimer.current);
            clearTimeout(deleteTimer.current);

            // Check if the column still exists before trying to change its style or other properties
            if (columnRef.current) {
                columnRef.current.style.filter = "";
                shakeTimeline.current.pause().seek(0);
                setIsCracked(false);
            }

            // Remove the event listeners
            document.removeEventListener('mouseup', handleMouseUpOrLeave);
            document.removeEventListener('mouseleave', handleMouseUpOrLeave);
        };


        columnRef.current.addEventListener('mousedown', handleMouseDown);
        columnRef.current.addEventListener('mouseup', handleMouseUpOrLeave);
        columnRef.current.addEventListener('mouseleave', handleMouseUpOrLeave);

        return () => {
            columnRef.current.removeEventListener('mousedown', handleMouseDown);
            columnRef.current.removeEventListener('mouseup', handleMouseUpOrLeave);
            columnRef.current.removeEventListener('mouseleave', handleMouseUpOrLeave);
        };
    }, [columnRef, setIsVisible, setIsCracked]);

    return shakeTimeline;
};