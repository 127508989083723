import React from 'react';
import './Header.css';  // Ensure this CSS file is properly linked
import TypeWriterText from './TypeWriter';

function Header() {
    return (
        <div className="header">
            <TypeWriterText />
        </div>
    );
}

export default Header;
