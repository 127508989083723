import React, { useState, useEffect } from 'react';
import './TypeWriter.css';  // Import the CSS for styles
import Typewriter from "typewriter-effect";

const TypeWriterText = () => {
  const [showRightText, setShowRightText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRightText(true);
    }, 3000); // Adjust the timeout to control when the rightText starts
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <h2 className="LeftTitle">
        <Typewriter
          options={{
            autoStart: true,
            loop: false,
            delay: 30,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString("The five characters from Boy's Club, a comic series<br />created by the visionary")
              .pauseFor(1000)
              .start();
          }}
        />
      </h2>
      {showRightText && (
        <h2 className="RightTitle">
          <Typewriter
            options={{
              autoStart: true,
              loop: false,
              delay: 48,
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString("Matt Furie")
                .start();
            }}
          />
        </h2>
      )}
    </>
  );
};

export default TypeWriterText;
